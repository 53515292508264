// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcSaveState,
	AcIsSet,
	AcIsUndefined,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	conversation: {
		latitude: null,
		longitude: null,
		campaign_action_id: null,
		answers: [],
	},
	result: [],
	message: '',
	questions: [],
	question: null,
	surprise: {
		content: '777',
		message_uid: 'badge',
		score: null,
	},
};

let app = {};

export class ConversationStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.CONVERSATION);
		AcAutoLoad(this, KEYS.QUESTIONS);
		AcAutoLoad(this, KEYS.QUESTION);
		AcAutoLoad(this, KEYS.RESULT);
		AcAutoLoad(this, KEYS.SURPRISE);

		app.store = store;
	}

	@observable
	conversation = _default.conversation;

	@observable
	questions = _default.questions;

	@observable
	question = _default.question;

	@observable
	result = _default.result;

	@observable
	surprise = _default.surprise;

	@observable
	loading = {
		status: false,
	};

	@computed
	get current_conversation() {
		return this.conversation;
	}

	@computed
	get current_questions() {
		return this.questions;
	}

	@computed
	get number_of_questions() {
		return this.current_questions && this.current_questions.length;
	}

	@computed
	get current_question() {
		return this.question;
	}

	@computed
	get current_result() {
		return this.result;
	}

	@computed
	get current_surprise() {
		return this.surprise;
	}

	@computed
	get current_progress() {
		if (!this.current_question)
			return {
				value: 0,
				max: 1,
			};

		const questions = this.current_questions;
		const current = this.current_question;
		const max = this.number_of_questions;

		const len = questions.length;
		let n = 0;
		let value = 0;

		for (n; n < len; n++) {
			if (questions[n].id === current.id) {
				value = n + 1;
				break;
			}
		}

		return {
			value,
			max,
		};
	}

	@computed
	get is_loading() {
		return this.loading.status;
	}

	@action
	setLoading = (state = false) => {
		this.loading = {
			status: state,
		};
	};

	@action
	absent = campaign_action_id => {
		if (!campaign_action_id) return;
		this.setLoading(true);

		const { latitude, longitude } = app.store.navigator.get();

		const data = {
			latitude: `${latitude}`,
			longitude: `${longitude}`,
			campaign_action_id,
		};

		return app.store.api.conversation
			.absent(data)
			.then(async response => {
				const { campaignAction, messages } = response;

				app.store.campaigns.set(KEYS.CAMPAIGN, campaignAction, true);
				app.store.campaigns.set(
					KEYS.SCORE,
					{ ...campaignAction.score, initial: false },
					true
				);

				await this.set(KEYS.RESULT, messages, true);

				app.store.toasters.add({
					title: 'Bedankt voor het doorgeven!',
					description: `'<strong>Niet thuis</strong>' staat genoteerd`,
				});

				this.setLoading(false);
			})
			.catch(error => {
				app.store.toasters.add({
					title: `Het doorgeven van '<strong>Niet thuis</strong>'' is niet gelukt`,
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);

				throw error;
			});
	};

	@action
	not_interested = campaign_action_id => {
		if (!campaign_action_id) return;
		this.setLoading(true);

		const { latitude, longitude } = app.store.navigator.get();

		const data = {
			latitude: `${latitude}`,
			longitude: `${longitude}`,
			campaign_action_id,
		};

		return app.store.api.conversation
			.not_interested(data)
			.then(async response => {
				const { campaignAction, messages } = response;

				app.store.campaigns.set(KEYS.CAMPAIGN, campaignAction, true);
				app.store.campaigns.set(
					KEYS.SCORE,
					{ ...campaignAction.score, initial: false },
					true
				);

				await this.set(KEYS.RESULT, messages, true);

				app.store.toasters.add({
					title: 'Bedankt voor het doorgeven!',
					description: `'<strong>Geen interesse</strong>' staat genoteerd`,
				});

				this.setLoading(false);
			})
			.catch(error => {
				app.store.toasters.add({
					title: `Het doorgeven van '<strong>Geen interesse</strong>'' is niet gelukt`,
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);

				throw error;
			});
	};

	@action
	new_conversation = async campaign_action_id => {
		if (!campaign_action_id) return;

		await this.get(campaign_action_id);

		const { latitude, longitude } = app.store.navigator.get();

		const data = {
			latitude: `${latitude}`,
			longitude: `${longitude}`,
			campaign_action_id,
			answers: [],
		};

		this.set(KEYS.CONVERSATION, data, true);
	};

	@action
	set_comment = value => {
		return new Promise(async resolve => {
			const conversation = this.current_conversation;

			conversation.message = value;

			await this.set(KEYS.CONVERSATION, conversation, true);
			resolve();
		});
	};

	@action
	set_answer = (question_id, value) => {
		return new Promise(async resolve => {
			const conversation = this.current_conversation;
			const { answers } = conversation;

			const len = answers.length;
			let n = 0;
			let found = false;

			for (n; n < len; n++) {
				const obj = answers[n];

				if (obj.question_id === question_id) {
					obj.value = value;

					answers[n] = obj;
					found = n;
					break;
				}
			}

			if (found === false) {
				answers.push({
					question_id,
					value,
				});
			} else {
				answers.length = found + 1;
			}

			conversation.answers = answers;

			await this.set(KEYS.CONVERSATION, conversation, true);
			resolve();
		});
	};

	@action
	next_question = id => {
		const questions = this.current_questions;

		const len = questions.length;
		let n = 0;
		let result = null;

		for (n; n < len; n++) {
			const q = questions[n];

			if (`${q.id}` === `${id}`) {
				if (AcIsSet(questions[n + 1])) {
					result = questions[n + 1];
					break;
				}
			}
		}

		if (result) {
			this.set(KEYS.QUESTION, result, true);
			return true;
		} else {
			return false;
		}
	};

	@action
	prev_question = id => {
		const questions = this.current_questions;

		const len = questions.length;
		let n = 0;
		let result = null;

		for (n; n < len; n++) {
			const q = questions[n];

			if (`${q.id}` === `${id}`) {
				if (AcIsSet(questions[n - 1])) {
					result = questions[n - 1];
					break;
				}
			}
		}

		if (result) {
			this.set(KEYS.QUESTION, result, true);
			return true;
		} else {
			return false;
		}
	};

	@action
	get = campaign_action_id => {
		if (!campaign_action_id) return;

		const data = { campaign_action_id };

		return app.store.api.conversation
			.get(data)
			.then(response => {
				const { questions } = response;
				this.set(KEYS.QUESTIONS, questions, true);
				if (!this.current_question) this.set(KEYS.QUESTION, questions[0], true);
			})
			.catch(error => {
				app.store.toasters.add({
					title: 'Ophalen van de vragenlijst is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	finish = () => {
		const { latitude, longitude } = app.store.navigator.get();

		const data = {
			latitude: `${latitude}`,
			longitude: `${longitude}`,
			...this.current_conversation,
		};

		return app.store.api.conversation
			.finish(data)
			.then(response => {
				this.set(KEYS.RESULT, response.messages, true);
			})
			.catch(error => {
				app.store.toasters.add({
					title: 'Opslaan van het gesprek is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	set = (target, value, save = true) => {
		return new Promise(resolve => {
			if (!AcIsSet(target)) return;
			if (AcIsUndefined(this[target])) return;
			if (AcIsUndefined(value)) return;

			this[target] = value;
			if (save) AcSaveState(target, value);
			resolve();
		});
	};

	@action
	reset = target => {
		if (!target) return;
		if (!this[target]) return;

		this.set(target, _default[target], true);
	};
}

export default ConversationStore;
