import 'preact/debug';
import React from 'react';
import { render } from 'preact';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import { register, unregister } from './registerServiceWorker';

import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import config from '@config';
import createStore from '@stores';

import App from '@src/App';

const browserHistory = createBrowserHistory();
const routing = new RouterStore();

const store = createStore(config);
const history = syncHistoryWithStore(browserHistory, routing);

const providers = {
	routing,
	config,
	store,
};

// Define SW callbacks
const onSuccess = () => {
	console.info('ServiceWorker registered');
};

const onUpdate = instance => {
	console.group('ServiceWorker updated');
	console.log('Reg:', instance);
	console.groupEnd();

	var swUpdatedEvent = new CustomEvent('swUpdated', {
		instance,
	});
	window.dispatchEvent(swUpdatedEvent);
};

// kick off the smoothscroll polyfill!
smoothscroll.polyfill();

const container = document.getElementById('root');

render(
	<Provider {...providers}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	container
);

if (process.env.NODE_ENV === 'production') {
	register({
		onSuccess,
		onUpdate,
	});
} else {
	unregister();
}
