// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcIsSet,
  AcIsUndefined,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsObject,
} from '@utils';

const _default = {
  options: {},
};

let app = {};

export class CampaignsStore {
  constructor(store) {
    AcAutoLoad(this, KEYS.CAMPAIGNS);
    AcAutoLoad(this, KEYS.CAMPAIGN);
    AcAutoLoad(this, KEYS.SELECTED);
    AcAutoLoad(this, KEYS.SCORE);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  campaign = null;

  @observable
  campaigns = null;

  @observable
  selected = null;

  @observable
  cheating_list = null;

  @observable
  tips_and_tricks = null;

  @observable
  score = {
    initial: true,
    meatball_score: 0,
    meatball_score_objective: 0,
  };

  @computed
  get current_score() {
    return this.score;
  }

  @computed
  get current_past_campaigns() {
    if (!this.campaigns || !this.campaigns.past) return [];
    const collection = this.campaigns.past;
    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const c = collection[n];

      c.subscribed = this.checkForSubscription(c);
      c.past = true;

      result.push(c);
    }

    return result;
  }

  @computed
  get current_campaigns() {
    if (!this.campaigns || !this.campaigns.all) return [];

    const collection = this.campaigns.all;
    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const c = collection[n];

      c.subscribed = this.checkForSubscription(c);

      result.push(c);
    }

    return [...result, ...this.current_past_campaigns];
  }

  @computed
  get current_subscribed_campaigns() {
    if (!this.campaigns || !this.campaigns.subscribed) return [];

    let collection = this.campaigns.subscribed;

    if (AcIsObject(collection)) {
      return Object.values(collection);
    }

    return collection;
  }

  @computed
  get selected_campaign_id() {
    return this.selected;
  }

  @computed
  get selected_campaign() {
    return this.campaign;
  }

  @computed
  get current_cheating_list() {
    return this.cheating_list;
  }

  @computed
  get current_tips_and_tricks() {
    return this.tips_and_tricks;
  }

  @observable
  requesting = {
    status: false,
  };

  @action
  setRequesting = state => {
    this.requesting = {
      status: state || false,
    };
  };

  @computed
  get is_requesting() {
    return this.requesting.status;
  }

  @observable
  loading = {
    status: false,
  };

  @action
  setLoading = state => {
    this.loading = {
      status: state || false,
    };
  };

  @computed
  get is_loading() {
    return this.loading.status;
  }

  @action
  checkForSubscription = campaign => {
    const collection = this.current_subscribed_campaigns;

    if (!collection) return false;

    const len = collection.length;
    let n = 0;
    let result = false;

    for (n; n < len; n++) {
      const opt = collection[n];

      if (campaign.id === opt.id) {
        result = true;
        break;
      }
    }

    return result;
  };

  @action
  checkForPastCampaign = campaign => {
    const collection = this.current_past_campaigns;

    if (!collection) return false;

    const len = collection.length;
    let n = 0;
    let result = false;

    for (n; n < len; n++) {
      const opt = collection[n];

      if (campaign.id === opt.id) {
        result = true;
        break;
      }
    }

    return result;
  };

  @action
  list = () => {
    this.setLoading(true);

    return app.store.api.campaigns
      .list()
      .then(response => {
        this.set(KEYS.CAMPAIGNS, response, true);

        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.setLoading(false);

        app.store.toasters.add({
          title: 'Ophalen van de acties is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  get_by_id = id => {
    if (!id) return;
    this.setLoading(true);

    return app.store.api.campaigns
      .get_by_id(id)
      .then(response => {
        const obj = {
          ...response,
          subscribed: this.checkForSubscription(response),
          past: this.checkForPastCampaign(response),
        };
        this.set(KEYS.CAMPAIGN, obj, true);
        this.set(KEYS.SELECTED, id, true);

        this.setLoading(false);
        return obj;
      })
      .catch(error => {
        this.setLoading(false);

        app.store.toasters.add({
          title: 'Ophalen van de actie is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  get_cheating_list = () => {
    this.setLoading(true);

    return app.store.api.campaigns
      .get_cheating_list()
      .then(response => {
        if (response.cheating_letter) {
          this.set(KEYS.CHEATING_LIST, response.cheating_letter, true);
        }

        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.setLoading(false);

        app.store.toasters.add({
          title: 'Ophalen van het spiekbriefje is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  get_tips_and_tricks = () => {
    this.setLoading(true);

    return app.store.api.campaigns
      .get_tips_and_tricks()
      .then(response => {
        if (response.tips_and_tricks) {
          this.set(KEYS.TIPS_AND_TRICKS, response.tips_and_tricks, true);
        }

        this.setLoading(false);
        return response;
      })
      .catch(error => {
        this.setLoading(false);

        app.store.toasters.add({
          title: 'Ophalen van de tips & tricks is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  signup = campaign_action_id => {
    if (!AcIsSet(campaign_action_id)) return;

    this.setRequesting(true);

    return app.store.api.campaigns
      .signup({ campaign_action_id })
      .then(async response => {
        await this.list();
        await this.get_by_id(campaign_action_id);
        this.setRequesting(false);

        let message = 'Je bent nu aangemeld';
        if (this.selected_campaign)
          message = `${message} voor de actie '<strong>${this.selected_campaign.name}</strong>'`;

        app.store.toasters.add({
          title: 'Fantastisch!',
          description: message,
        });

        return response;
      })
      .catch(error => {
        this.setRequesting(false);

        app.store.toasters.add({
          title: 'Aanmelden is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  signout = campaign_action_id => {
    if (!AcIsSet(campaign_action_id)) return;

    this.setRequesting(true);

    return app.store.api.campaigns
      .signout({ campaign_action_id })
      .then(async response => {
        await this.list();
        await this.get_by_id(campaign_action_id);
        this.setRequesting(false);

        let message = 'Je bent nu afgemeld';
        if (this.selected_campaign)
          message = `${message} voor de actie '<strong>${this.selected_campaign.name}</strong>'`;

        app.store.toasters.add({
          title: 'Bedankt voor het doorgeven!',
          description: message,
        });

        return response;
      })
      .catch(error => {
        this.setRequesting(false);

        app.store.toasters.add({
          title: 'Afmelden is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });
        throw error;
      });
  };

  @action
  start = campaign_action_id => {
    if (!AcIsSet(campaign_action_id)) return;

    this.setRequesting(true);

    return app.store.api.campaigns
      .start({ campaign_action_id })
      .then(async response => {
        await this.list();
        await this.get_by_id(campaign_action_id);
        this.setRequesting(false);

        let message = 'De actie is gestart';
        if (this.selected_campaign)
          message = `Actie '${this.selected_campaign.name}' is gestart`;

        app.store.toasters.add({
          title: 'Veel succes!',
          description: message,
        });

        return response;
      })
      .catch(error => {
        this.setRequesting(false);

        app.store.toasters.add({
          title: 'Actie starten is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  end = campaign_action_id => {
    if (!AcIsSet(campaign_action_id)) return;

    this.setRequesting(true);

    return app.store.api.campaigns
      .end({ campaign_action_id })
      .then(async response => {
        await this.list();
        await this.get_by_id(campaign_action_id);
        this.setRequesting(false);

        let message = 'De actie is afgerond';
        if (this.selected_campaign)
          message = `Actie '${this.selected_campaign.name}' is afgerond`;

        app.store.toasters.add({
          title: 'Bedankt voor je inzet!',
          description: message,
        });

        return response;
      })
      .catch(error => {
        this.setRequesting(false);

        app.store.toasters.add({
          title: 'Actie afronden is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        throw error;
      });
  };

  @action
  setSelectedCampaign = id => {
    return this.set(KEYS.SELECTED, id, true);
  };

  @action
  clearSelectedCampaign = async () => {
    await this.set(KEYS.CAMPAIGN, null, true);
    await this.set(
      KEYS.SCORE,
      {
        initial: true,
        meatball_score: 0,
        meatball_score_objective: 0,
      },
      true
    );
    return this.set(KEYS.SELECTED, null, true);
  };

  @action
  set = (target, value, save) => {
    return new Promise(resolve => {
      if (!AcIsSet(target)) return;
      if (AcIsUndefined(this[target])) return;
      if (AcIsUndefined(value)) return;

      this[target] = value;
      if (save) AcSaveState(target, value);
      resolve();
    });
  };

  @action
  setState = (target, property, value, save) => {
    return new Promise(resolve => {
      if (!AcIsSet(target)) return;
      if (AcIsUndefined(this[target])) return;
      if (!AcIsSet(property)) return;
      if (AcIsUndefined(value)) return;

      this[target][property] = value;
      if (save) AcSaveState(target, value);
      resolve();
    });
  };

  @action
  reset = (target, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise(resolve => {
      this[target] = _default[target];
      if (save) AcSaveState(target, _default[target]);

      resolve();
    });
  };
}

export default CampaignsStore;
