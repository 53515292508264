// Imports => React
import React, { useEffect, useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Switch, withRouter, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import clsx from 'clsx';

// Imports => SCSS
import '@styles/index.scss';

// Imports => Constants
import { DEFAULT_ROUTE, KEYS, TITLES, ROUTES } from '@constants';

// Imports => Utilities
import { AcSupportsWEBP } from '@utils';

// Imports => Molecules
import AcToasterHoc from '@molecules/ac-toaster-hoc/ac-toaster-hoc.web';
import AcModal from '@molecules/ac-modal/ac-modal.web';
import AcNotConnectedModal from '@molecules/ac-not-connected-modal/ac-not-connected-modal.web';
import AcLocationRequiredModal from '@molecules/ac-location-required-modal/ac-location-required-modal.web';

// Imports => Atoms
import AcErrorBoundary from '@atoms/ac-error-boundary/ac-error-boundary.web';
import AcPrivateRoute from '@atoms/ac-private-route/ac-private-route.web';
import AcSplashScreen from '@views/ac-splash-screen/ac-splash-screen.web';
import AcScrollHOC from '@atoms/ac-scroll-hoc/ac-scroll-hoc.web';

const _CLASSES = {
	ROOT: 'ac-root',
	MAIN: 'ac-app',
	ROUTE: {
		SECTION: 'ac-route__section',
	},
};

const App = ({
	history,
	match,
	store,
	store: {
		profile,
		auth: { loaded, is_authorized },
		campaigns,
		navigator,
		ui,
		current_sw_instance,
	},
}) => {
	const location = useLocation();

	useEffect(() => {
		handleRouteChanged();
	}, [location]);

	useEffect(() => {
		if (!navigator.is_connected) displayNotConnectedModal();
		else {
			store.ui.reset(KEYS.MODAL);
		}
	}, [navigator.is_connected]);

	useEffect(() => {
		console.log('navigator.has_permission', navigator.has_permission);
		if (!navigator.has_permission) displayLocationRequiredModal();
		else locationModalCallback();
	}, [navigator.has_permission]);

	useEffect(() => {
		if (current_sw_instance) {
			const instanceWaiting = current_sw_instance.waiting;

			if (instanceWaiting) {
				instanceWaiting.postMessage({ type: 'SKIP_WAITING' });

				instanceWaiting.addEventListener('statechange', event => {
					if (event.target.state === 'activated') {
						window.location.reload();
					}
				});
			}
		}
	}, [current_sw_instance]);

	const locationModalCallback = () => {
		if (navigator.has_permission) {
			store.ui.reset(KEYS.MODAL);
		}
	};

	const displayLocationRequiredModal = async event => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		await store.ui.reset(KEYS.MODAL);
		await store.ui.set(KEYS.MODAL, {
			title: TITLES.LOCATION_REQUIRED,
			body: <AcLocationRequiredModal callback={locationModalCallback} />,
			offset: true,
			closeable: false,
			visible: true,
			actions: [],
			callback: () => {
				store.ui.reset(KEYS.MODAL);
			},
		});
	};

	const displayNotConnectedModal = async event => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		await store.ui.reset(KEYS.MODAL);
		await store.ui.set(KEYS.MODAL, {
			title: null,
			body: <AcNotConnectedModal />,
			offset: true,
			closeable: false,
			visible: true,
			actions: [],
			callback: () => {
				store.ui.reset(KEYS.MODAL);
			},
		});
	};

	const handleRouteChanged = event => {
		ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, false);
		ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);

		if (is_authorized) {
			profile.who_am_i();
			campaigns.list();
		}
	};

	const getRouteSectionClassNames = useMemo(() => {
		return clsx(_CLASSES.ROUTE.SECTION);
	}, []);

	const renderDefaultRoute = useMemo(() => {
		return (
			<AcPrivateRoute
				component={DEFAULT_ROUTE.component}
				forbidden={DEFAULT_ROUTE.forbidden}
				store={store}
			/>
		);
	}, [is_authorized]);

	const renderModal = useMemo(() => {
		return <AcModal {...store.ui.modal}>{store.ui.modal.body}</AcModal>;
	}, [store.ui.modal, store.ui.modal.closeable, store.ui.modal.visible]);

	return (
		<AcErrorBoundary screen={location.pathname}>
			<AcScrollHOC>
				<main className={AcSupportsWEBP() ? 'ac-supports-webp' : ''}>
					<section id={KEYS.SCROLLER} className={getRouteSectionClassNames}>
						<TransitionGroup>
							<CSSTransition
								key={location.key}
								timeout={{ enter: 300, exit: 300 }}
								classNames={'fade'}
							>
								<Switch location={location}>
									{ROUTES &&
										Object.keys(ROUTES)
											.filter(route =>
												ROUTES[route].forbidden ? is_authorized : true
											)
											.map(route => (
												<AcPrivateRoute
													key={`route-${ROUTES[route].id}`}
													name={ROUTES[route].name}
													path={ROUTES[route].path}
													component={ROUTES[route].component}
													forbidden={ROUTES[route].forbidden}
													authorized={is_authorized}
													store={store}
													exact={ROUTES[route].exact}
												/>
											))}
									{DEFAULT_ROUTE && renderDefaultRoute}
								</Switch>
							</CSSTransition>
						</TransitionGroup>
					</section>

					{renderModal}

					<AcToasterHoc
						queue={store.toasters.queue}
						callback={store.toasters.remove}
					/>

					{!is_authorized && <AcSplashScreen />}
				</main>
			</AcScrollHOC>
		</AcErrorBoundary>
	);
};

export default withRouter(inject('store')(observer(App)));
