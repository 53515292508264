// Imports => Dependencies
import { observable, computed, action } from 'mobx';

// Imports => API
import createApi from '@api';

// Imports => Stores
import AuthStore from '@stores/auth.store';
import CampaignsStore from '@stores/campaigns.store';
import ConversationStore from '@stores/conversation.store';
import ProfileStore from '@stores/profile.store';
import ToastersStore from '@stores/toasters.store';
import UiStore from '@stores/ui.store';
import NavigatorStore from '@stores/navigator.store';

class Store {
	constructor(config) {
		this.config = config;

		this.api = createApi({
			config: config,
		});

		this.navigator = new NavigatorStore(this);

		this.auth = new AuthStore(this);
		this.campaigns = new CampaignsStore(this);
		this.conversation = new ConversationStore(this, this.navigator);
		this.profile = new ProfileStore(this);
		this.toasters = new ToastersStore(this);
		this.ui = new UiStore(this);

		window.addEventListener('swUpdated', event => {
			this.swUpdated(event);
		});
	}

	@observable
	sw_instance = null;

	@computed
	get current_sw_instance() {
		return this.sw_instance;
	}

	@action
	swUpdated = event => {
		if (event && event.instance) this.sw_instance = event.instance;
	};
}

export default Store;
