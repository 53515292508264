// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcIsSet,
	AcFormatErrorMessage,
	AcFormatErrorCode,
	AcIsUndefined,
} from '@utils';

const _default = {
	options: {},
	profile: {
		id: '',
		name: '',
		email: '',
		usps: {},
	},
	error: null,
};

let app = {};

export class ProfileStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.PROFILE);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	error = null;

	@observable
	profile = null;

	@computed
	get current_profile() {
		return this.profile;
	}

	@computed
	get current_error() {
		return this.error;
	}

	@computed
	get current_role() {
		return this.profile && this.profile.role;
	}

	@computed
	get current_municipality() {
		if (!this.current_profile) return null;

		const { municipalities } = this.current_profile;

		const len = municipalities.length;
		let n = 0;
		let result = [];

		if (len === 0) return null;

		for (n; n < len; n++) {
			result.push(municipalities[n].text);
		}

		return result.join('<br/>');
	}

	@computed
	get is_loading() {
		return this.loading.status;
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading = (state, message) => {
		this.loading = {
			status: state || false,
			message: message || 'Checking your profile',
		};
	};

	@action
	who_am_i = () => {
		return app.store.api.profile
			.who_am_i()
			.then(response => {
				this.set(KEYS.PROFILE, response, true);

				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					title: 'Ophalen van je profiel is niet gelukt.',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	update = data => {
		this.setLoading(true);

		return app.store.api.profile
			.update(data)
			.then(response => {
				this.set(KEYS.PROFILE, response, true);

				app.store.toasters.add({
					title: 'Je instellingen zijn opgeslagen.',
				});

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				this.setLoading(false);

				app.store.toasters.add({
					title: 'Opslaan van je instellingen is niet gelukt.',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				if (AcFormatErrorMessage(error) === 'Wachtwoord is incorrect') {
					this.set(KEYS.ERROR, 'Pin is onjuist');
				}

				throw error;
			});
	};

	@action
	set = (target, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		this[target] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	setState = (target, property, value, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (!AcIsSet(property)) return;
		if (AcIsUndefined(value)) return;

		this[target][property] = value;
		if (save) AcSaveState(target, value);
	};

	@action
	reset = (target, save) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;

		return new Promise(resolve => {
			this[target] = _default[target];
			if (save) AcSaveState(target, _default[target]);

			resolve();
		});
	};
}

export default ProfileStore;
