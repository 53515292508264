// Imports => React
import React, { useEffect, useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, VISUALS } from '@constants';

const _CLASSES = {
	MAIN: 'ac-splash-screen',
	LOADED: 'ac-splash-screen--loaded',
	LOGO: 'ac-splash-screen__logo',
	VISUAL: 'ac-splash-screen__visual',
};

let splash = null;

const AcSplashScreen = ({ history, store }) => {
	const { loaded } = store.auth;

	useEffect(() => {
		if (splash) clearTimeout(splash);
		splash = setTimeout(async () => {
			await store.auth.set(KEYS.LOADED, true);
		}, 600);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, loaded && _CLASSES.LOADED);
	}, [loaded]);

	const getVisualClassNames = useMemo(() => {
		return clsx(_CLASSES.VISUAL);
	}, []);

	const renderIGLogo = useMemo(() => {
		const Tag = VISUALS.IG_LOGO;

		return <Tag />;
	}, []);

	return (
		<div className={getMainClassNames}>
			<div className={getVisualClassNames}>{renderIGLogo}</div>
		</div>
	);
};

export default withRouter(inject('store')(observer(AcSplashScreen)));
