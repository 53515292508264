// Imports => Dependencies
import { observable, computed, action } from 'mobx';

// Imports => Moment
import moment from 'moment';
import 'moment/locale/nl';

// Imports => Config
import config from '@config';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcGetState,
	AcSaveState,
	AcClearState,
	AcIsSet,
	AcIsUndefined,
	AcFormatErrorMessage,
	AcFormatErrorCode,
} from '@utils';

let app = {};
let timer = null;

export class AuthStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.XUSR_TOKEN);
		AcAutoLoad(this, KEYS.ACCOUNT);
		AcAutoLoad(this, KEYS.ACCESS_TOKEN);
		AcAutoLoad(this, KEYS.EXPIRES_IN);
		AcAutoLoad(this, KEYS.EXPIRES_AT);
		AcAutoLoad(this, KEYS.REFRESH_TOKEN);
		AcAutoLoad(this, KEYS.EMAIL);
		AcAutoSave(this);

		moment.locale(config.locale);

		app.store = store;

		window.addEventListener('unAuthenticate', event => {
			this.unAuthenticate();
		});
	}

	@observable
	xusr_token = null;

	@observable
	redirect_url = null;

	@observable
	access_token = null;

	@observable
	expires_in = null;

	@observable
	expires_at = null;

	@observable
	account = null;

	@observable
	attempts = 0;

	@observable
	max_attempts = 3;

	@observable
	loaded = false;

	@observable
	email = null;

	@observable
	locked = false;

	@observable
	locked_timer = null;

	@observable
	error = null;

	@observable
	loading = {
		status: false,
		message: undefined,
	};

	@computed
	get is_loading() {
		return this.loading.status;
	}

	@computed
	get current_error() {
		return this.error;
	}

	@computed
	get current_access_token() {
		return this.access_token;
	}

	@computed
	get current_expires_at() {
		return this.expires_at;
	}

	@computed
	get current_token() {
		return this.xusr_token;
	}

	@computed
	get current_email() {
		return this.email;
	}

	@computed
	get current_attempts() {
		return this.attempts;
	}

	@computed
	get has_token() {
		return this.current_token || this.current_access_token;
	}

	@computed
	get current_account() {
		return this.account;
	}

	@computed
	get current_username() {
		return (this.account && this.account.user_name) || null;
	}

	@computed
	get current_role() {
		return (this.account && this.account.user_role) || null;
	}

	@computed
	get current_name() {
		return (this.account && this.account.name) || null;
	}

	@computed
	get is_authorized() {
		let authorized = false;

		const account = this.current_account;
		const access_token = this.current_access_token;
		const expires_at = this.current_expires_at;
		const now = moment();
		let expired = true;
		let expires_in = 0;

		if (expires_at) {
			expired = expires_at && moment(expires_at, 'X').isBefore(now);
			expires_in = expires_at && moment(expires_at, 'X').fromNow();
		}

		console.group('[store] Auth => Is Authorized');
		console.log('Now:', moment(now).format('LLLL:ss'));
		console.log('Expires_at: ', moment(expires_at, 'X').format('LLLL:ss'));
		console.log('Expires in: ', expires_in);
		console.log('Is Expired: ', expired);

		authorized = account && access_token && !expired ? true : false;

		console.log('Authorized: ', authorized);
		console.groupEnd();

		return authorized === true;
	}

	@action
	setLoading = (state, message) => {
		this.loading = {
			status: state || false,
			message: message || false,
		};
	};

	@action
	clearAuthentication = () => {
		return new Promise(async resolve => {
			const email = AcGetState(KEYS.EMAIL);
			await this.set(KEYS.ACCOUNT, null);
			await this.set(KEYS.ACCESS_TOKEN, null);
			await this.set(KEYS.REFRESH_TOKEN, null);
			await this.set(KEYS.EXPIRES_IN, null);
			await this.set(KEYS.EXPIRES_AT, null);
			await this.set(KEYS.XUSR_TOKEN, null);
			await this.set(KEYS.PROFILE, null);
			AcClearState();
			await this.set(KEYS.EMAIL, email);
			resolve();
		});
	};

	@action
	handleAuthentication = result => {
		if (result) {
			const { access_token, expires_in } = result;

			if (access_token) this.set(KEYS.ACCESS_TOKEN, access_token);

			if (expires_in) {
				const expires_at = moment()
					.add(expires_in, 'seconds')
					.format('X');
				this.set(KEYS.EXPIRES_IN, expires_in);
				this.set(KEYS.EXPIRES_AT, expires_at);
			}

			if (result) this.set(KEYS.ACCOUNT, result);
		}
	};

	@action
	startTimer = () => {
		if (timer) clearTimeout(timer);

		timer = setTimeout(() => {}, 1000);
	};

	@action
	clearAttempts = () => {
		if (timer) clearTimeout(timer);
		this.set(KEYS.ATTEMPTS, 0);
		this.set(KEYS.LOCKED, 0);
		this.set(KEYS.LOCKED_TIMER, null);
	};

	@action
	updateAttempts = () => {
		const a = this.attempts + 1;
		this.set(KEYS.ATTEMPTS, a);
	};

	@action
	login = credentials => {
		if (this.locked) return;
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.login(credentials)
			.then(response => {
				this.clearAttempts();
				this.handleAuthentication(response);

				this.setLoading(false);

				app.store.toasters.clear();

				return response;
			})
			.catch(error => {
				this.updateAttempts();
				this.setLoading(false);

				app.store.toasters.add({
					title: 'Inloggen is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	forgot_password = credentials => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.forgot_password(credentials)
			.then(response => {
				this.setLoading(false);

				app.store.toasters.add({
					title: 'Nieuwe pincode is aangevraagd',
				});

				return response;
			})
			.catch(error => {
				this.setLoading(false);

				app.store.toasters.add({
					title: 'Aanvragen van een nieuwe pincode is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	reset_password = credentials => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.reset_password(credentials)
			.then(response => {
				this.setLoading(false);

				app.store.toasters.add({
					title: 'Nieuwe pincode is opgeslagen',
				});

				return response;
			})
			.catch(error => {
				this.setLoading(false);

				app.store.toasters.add({
					title: 'Opslaan van je nieuwe pincode is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	logout = () => {
		if (timer) clearTimeout(timer);

		return new Promise(async resolve => {
			await this.set(KEYS.LOADED, false);
			await this.clearAuthentication();

			app.store.toasters.clear();
			app.store.toasters.add({
				title: 'Je bent uitgelogd',
			});

			resolve();
		});
	};

	@action
	unAuthenticate = () => {
		if (timer) clearTimeout(timer);

		return new Promise(async resolve => {
			await this.set(KEYS.LOADED, false);
			await this.clearAuthentication();

			app.store.toasters.clear();
			app.store.toasters.add({
				title: 'De sessie is verlopen. Log opnieuw in op door te gaan.',
			});

			resolve();
		});
	};

	@action
	set = (target, value) => {
		if (!AcIsSet(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise(resolve => {
			this[target] = value;
			AcSaveState(target, value);
			resolve();
		});
	};
}

export default AuthStore;
